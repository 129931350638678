/**
 * ! Change CompaniesSelection by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import WorldMap from './world-map.js';

const CompaniesSelection = ({ properties }) => {
    const { companies } = properties;
    return (
        <>            
            <WorldMap companies={companies} />
        </>
    );
};

CompaniesSelection.propTypes = {
    properties: PropTypes.shape({
        pageName: PropTypes.string,
        companies: PropTypes.array
    })
};

export default CompaniesSelection;

